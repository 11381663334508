import { GridApi } from 'ag-grid-community';
import { RoleSelect } from 'components/selects/RoleSelect';
import { useCallback, useState } from 'react';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'role.roleId' as const;

/**
 * Parameters of the RoleSelectFilter component.
 */
export interface RoleSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the role column.
 *
 * This filter assumes:
 *   - column field is `role.roleId`
 *   - context contains `initialFilters` with `role.roleId` filter
 */
export default function RoleSelectFilter({ api, context }: RoleSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[FILTER_KEY]?.filter);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  return (
    <RoleSelect lazyLoad clearable w="100%" size="sm" placeholder="Všetky role" value={value} onChange={onChange} />
  );
}
