import { Button, createStyles, Group, rem, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import H3Medium from 'components/typography/H3Medium';
import FormSection, { FormSectionTexts } from 'pages/revisions-module/template-editor/editors/form/section/FormSection';
import { useMemo } from 'react';
import { IConfigureInputParams } from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import { pick } from 'lodash';
import { ALL_TYPES_ON } from 'pages/revisions-module/template-editor/editors/form/configurator/InputTypeSelect';
import { IFormInputType } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Styles for the DevicesTab component.
 */
const useStyles = createStyles((theme) => ({
  measurements: {
    borderLeft: `${rem(8)} solid ${theme.colors.gray[0]}`,
  },
}));

/**
 * The measurements for each device.
 */
export default function DeviceMeasurements() {
  const { classes } = useStyles();
  const { sections, addSection } = useFormSections();

  const measurementSections = useMemo(() => sections.filter((section) => section.tab === 'measurements'), [sections]);

  const { configureInputParams, sectionTexts } = useMemo(() => {
    const sectionTexts: FormSectionTexts = {
      removeTooltip: 'Odstrániť tabuľku meraní',
      removeConfirm: 'Naozaj chcete odstrániť túto tabuľku meraní? Táto akcia je nevratná.',
      moveTooltip: 'Presunúť tabuľku meraní (drag & drop)',
      newRow: 'Pridať nový stĺpec',
      rowTexts: {
        removeTooltip: 'Odstrániť stĺpec',
        removeConfirm: 'Naozaj chcete odstrániť tento stĺpec? Táto akcia je nevratná.',
        moveTooltip: 'Presunúť stĺpec (drag & drop)',
        newInput: '', // The button is disabled
      },
    };

    const allowedInputTypes: IFormInputType[] = ['text', 'number', 'date', 'checkbox', 'select'];

    const configureInputParams: Partial<IConfigureInputParams> = {
      hidden: { readOnly: true },
      inputTypes: pick(ALL_TYPES_ON, allowedInputTypes),
    };

    return { sectionTexts, configureInputParams };
  }, []);

  return (
    <Stack spacing={24} pl={16} className={classes.measurements}>
      <H3Medium>Tabuľky meraní</H3Medium>
      {measurementSections.map((section) => (
        <FormSection
          key={section.id}
          data={section}
          context="measurement"
          maxInputsInRow={1}
          texts={sectionTexts}
          configureInputParams={configureInputParams}
        />
      ))}
      <Group>
        <Button variant="tertiary" size="lg" leftIcon={<IconPlus />} onClick={() => addSection('measurements')}>
          Pridať ďalšiu tabuľku meraní
        </Button>
      </Group>
    </Stack>
  );
}
