import { createContext, useCallback, useContext, useMemo } from 'react';
import { IFormRow } from 'pages/revisions-module/template-editor/editors/form/types';
import { nanoid } from 'nanoid';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';

interface IFormRowsDataContext {
  mutateSectionRows: (sectionId: string, modify: (rows: IFormRow[]) => IFormRow[]) => void;
  addRow: (sectionId: string) => string;
  removeRow: (sectionId: string, rowId: string) => void;
}

const FormRowsDataContext = createContext<IFormRowsDataContext>(undefined!);

/**
 * Provides the form rows data to the form editor.
 */
export function FormRowsDataProvider({ children }: { children: React.ReactNode }) {
  const { mutateSections } = useFormSections();

  const mutateSectionRows = useCallback((sectionId: string, modify: (rows: IFormRow[]) => IFormRow[]) => {
    mutateSections((prev) =>
      prev.map((section) => (section.id === sectionId ? { ...section, rows: modify(section.rows) } : section))
    );
  }, []);

  const addRow = useCallback((sectionId: string) => {
    const row = { id: nanoid(), inputs: [] };
    mutateSectionRows(sectionId, (rows) => [...rows, row]);
    return row.id;
  }, []);

  const removeRow = useCallback((sectionId: string, rowId: string) => {
    mutateSectionRows(sectionId, (rows) => rows.filter((row) => row.id !== rowId));
  }, []);

  const value = useMemo(() => ({ mutateSectionRows, addRow, removeRow }), [mutateSectionRows, addRow, removeRow]);

  return <FormRowsDataContext.Provider value={value}>{children}</FormRowsDataContext.Provider>;
}

/**
 * Uses the form rows data.
 */
export function useFormRows() {
  return useContext(FormRowsDataContext);
}
