import { ActionIcon, Box, Button, createStyles, Group, rem, Tooltip } from '@mantine/core';
import { IconMenu, IconPlus, IconTrashX } from '@tabler/icons-react';
import FormInput from 'pages/revisions-module/template-editor/editors/form/input/FormInput';
import { useMemo } from 'react';
import { useConfirm } from 'components/modals/message/MessageProvider';
import {
  IConfigureInputParams,
  useInputConfigurator,
} from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';
import { IFormInputContext, IFormRow } from 'pages/revisions-module/template-editor/editors/form/types';
import { useFormRows } from 'pages/revisions-module/template-editor/editors/form/row/FormRowsDataProvider';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';

/**
 * Texts for the FormRow component.
 */
export interface FormRowTexts {
  removeTooltip: string;
  removeConfirm: string;
  moveTooltip: string;
  newInput: string;
}

/**
 * Parameters of the FormRow component.
 */
interface FormRowParams {
  sectionId: string;
  data: IFormRow;
  context: IFormInputContext;
  maxInputsInRow?: number;
  texts?: FormRowTexts;
  configureInputParams?: Partial<IConfigureInputParams>;
}

/**
 * Styles for the FormRow component.
 */
const useStyles = createStyles((theme) => ({
  wrapper: {
    borderBottom: `${rem(1)} solid ${theme.colors.gray[3]}`,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: rem(16),
    alignItems: 'center',
  },
}));

/**
 * A single row in a form section.
 */
export default function FormRow({
  sectionId,
  data,
  context,
  maxInputsInRow = 999,
  texts = {
    removeTooltip: 'Odstrániť riadok',
    removeConfirm: 'Naozaj chcete odstrániť tento riadok? Táto akcia je nevratná.',
    moveTooltip: 'Presunúť riadok (drag & drop)',
    newInput: 'Pridať pole v riadku',
  },
  configureInputParams = {},
}: FormRowParams) {
  const { classes } = useStyles();
  const { confirm } = useConfirm();
  const { removeRow } = useFormRows();
  const { addInput } = useFormInputs();
  const { configureInput } = useInputConfigurator();

  const canHaveMoreInputs = useMemo(() => data.inputs.length < maxInputsInRow, [data.inputs.length, maxInputsInRow]);
  const canBeDeleted = useMemo(() => data.inputs.every(({ deletable }) => deletable), [data.inputs]);

  /** Confirm and remove the row. */
  const confirmRemoveRow = () =>
    confirm({
      title: texts.removeTooltip,
      content: texts.removeConfirm,
      onConfirm: () => removeRow(sectionId, data.id),
    });

  /** Configure and add a new input. */
  const configureAndAddInput = () =>
    configureInput({
      ...configureInputParams,
      primaryButtonLabel: texts.newInput,
      onSubmit: (spec) => addInput(sectionId, data.id, context, spec),
    });

  return (
    <Box py={8} className={classes.wrapper}>
      <Tooltip label={texts.moveTooltip} openDelay={300} withArrow={false}>
        <ActionIcon variant="subtle-gray" size="sm">
          <IconMenu />
        </ActionIcon>
      </Tooltip>

      <Box className="grid items-start gap-4" style={{ gridTemplateColumns: `repeat(${data.inputs.length}, 1fr)` }}>
        {data.inputs.map((input) => (
          <FormInput
            key={input.id}
            sectionId={sectionId}
            rowId={data.id}
            data={input}
            configureInputParams={configureInputParams}
          />
        ))}
      </Box>

      <Group spacing={12} noWrap>
        {canHaveMoreInputs && (
          <Button variant="secondary" size="md" leftIcon={<IconPlus />} onClick={configureAndAddInput}>
            {texts.newInput}
          </Button>
        )}
        <Tooltip label={texts.removeTooltip} openDelay={300} withArrow={false}>
          <ActionIcon variant="subtle-gray" size="md" onClick={confirmRemoveRow} disabled={!canBeDeleted}>
            <IconTrashX />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Box>
  );
}
