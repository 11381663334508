import { IFilterParams } from 'ag-grid-community';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export default forwardRef(({ filterChangedCallback }: IFilterParams, ref) => {
  const [value, setValue] = useState<number[]>([]);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass({ data }: { data: { revisionStatus: { revisionStatusId: number } } }) {
        return value.includes(data.revisionStatus.revisionStatusId);
      },

      isFilterActive() {
        return value.length > 0;
      },

      onFloatingFilterChanged(value: number[]) {
        setValue(value);
      },
    };
  });

  useEffect(() => {
    filterChangedCallback();
  }, [value]);

  return <></>;
});
