import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { IForm, IFormSection, IFormTabSlug } from 'pages/revisions-module/template-editor/editors/form/types';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { nanoid } from 'nanoid';

interface IFormSectionsDataContext {
  sections: IFormSection[];
  newestSectionId: string;
  getSectionById: (sectionId: string) => IFormSection | undefined;
  mutateSections: (mutate: (sections: IFormSection[]) => IFormSection[]) => void;
  addSection: (tab: IFormTabSlug) => string;
  removeSection: (sectionId: string) => void;
  updateSectionName: (sectionId: string, name: string) => void;
}

const FormSectionsDataContext = createContext<IFormSectionsDataContext>(undefined!);

/**
 * Provides the form sections data to the form editor.
 */
export function FormSectionsDataProvider({ children }: { children: React.ReactNode }) {
  const {
    form: { sections },
    setForm,
  } = useTemplateEditorData();

  const [newestSectionId, setNewSectionId] = useState<string>('');

  const getSectionById = useCallback((sectionId: string) => sections.find(({ id }) => id === sectionId), [sections]);

  const mutateSections = useCallback((mutate: (sections: IFormSection[]) => IFormSection[]) => {
    setForm((prev: IForm) => ({ ...prev, sections: mutate(prev.sections) }));
  }, []);

  const addSection = useCallback((tab: IFormTabSlug) => {
    const section = { id: nanoid(), tab, name: '', rows: [] };
    mutateSections((prev) => [...prev, section]);
    setNewSectionId(section.id);
    return section.id;
  }, []);

  const removeSection = useCallback((sectionId: string) => {
    mutateSections((prev) => prev.filter((section) => section.id !== sectionId));
  }, []);

  const updateSectionName = useCallback((sectionId: string, name: string) => {
    mutateSections((prev) => prev.map((section) => (section.id === sectionId ? { ...section, name } : section)));
  }, []);

  const value = useMemo(
    () => ({ sections, newestSectionId, getSectionById, mutateSections, addSection, removeSection, updateSectionName }),
    [sections, newestSectionId, getSectionById, mutateSections, addSection, removeSection, updateSectionName]
  );

  return <FormSectionsDataContext.Provider value={value}>{children}</FormSectionsDataContext.Provider>;
}

/**
 * Uses the form sections data.
 */
export function useFormSections() {
  return useContext(FormSectionsDataContext);
}
