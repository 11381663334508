import { createContext, useCallback, useContext, useMemo } from 'react';
import {
  IFormInput,
  IFormInputContext,
  IFormInputSpec,
} from 'pages/revisions-module/template-editor/editors/form/types';
import { nanoid } from 'nanoid';
import { useFormRows } from 'pages/revisions-module/template-editor/editors/form/row/FormRowsDataProvider';
import slugify from 'slugify';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';

interface IFormInputsDataContext {
  allInputs: IFormInput[];
  getInputByName: (name: string) => IFormInput | undefined;
  mutateRowInputs: (sectionId: string, rowId: string, modify: (inputs: IFormInput[]) => IFormInput[]) => void;
  addInput: (sectionId: string, rowId: string, context: IFormInputContext, spec: IFormInputSpec) => string;
  removeInput: (sectionId: string, rowId: string, inputId: string) => void;
  updateInputSpec: (sectionId: string, rowId: string, inputId: string, spec: IFormInputSpec) => void;
  generateInputName: (label: string) => string;
}

const FormInputsDataContext = createContext<IFormInputsDataContext>(undefined!);

/**
 * Provides the form inputs data to the form editor.
 */
export function FormInputsDataProvider({ children }: { children: React.ReactNode }) {
  const { setForm, form } = useTemplateEditorData();
  const { mutateSectionRows } = useFormRows();

  const allInputs = useMemo(
    () => form.sections.flatMap((section) => section.rows.flatMap((row) => row.inputs)),
    [form.sections]
  );

  const addInputName = useCallback((name: string) => {
    setForm((form) => ({ ...form, inputNames: [...form.inputNames, name] }));
  }, []);

  const getInputByName = useCallback(
    (name: string) => allInputs.find((input) => input.spec.name === name),
    [allInputs]
  );

  const mutateRowInputs = useCallback(
    (sectionId: string, rowId: string, modify: (inputs: IFormInput[]) => IFormInput[]) => {
      mutateSectionRows(sectionId, (rows) =>
        rows.map((row) => (row.id === rowId ? { ...row, inputs: modify(row.inputs) } : row))
      );
    },
    []
  );

  const addInput = useCallback((sectionId: string, rowId: string, context: IFormInputContext, spec: IFormInputSpec) => {
    const input: IFormInput = { id: nanoid(), deletable: true, context, spec };
    mutateRowInputs(sectionId, rowId, (inputs) => [...inputs, input]);
    addInputName(input.spec.name);
    return input.id;
  }, []);

  //  Do not remove the input name from the list of input names, as it may
  //  already be used in the form data.
  const removeInput = useCallback((sectionId: string, rowId: string, inputId: string) => {
    mutateRowInputs(sectionId, rowId, (inputs) => inputs.filter((input) => input.id !== inputId));
  }, []);

  const updateInputSpec = useCallback((sectionId: string, rowId: string, inputId: string, spec: IFormInputSpec) => {
    mutateRowInputs(sectionId, rowId, (inputs) =>
      inputs.map((input) => (input.id === inputId ? { ...input, spec } : input))
    );
  }, []);

  const generateInputName = useCallback(
    (label: string) => {
      const name = slugify(label, { lower: true, trim: true, strict: true }).replace(/_/g, '-');
      const matchNameWithoutSuffix = new RegExp(`^${name}(_\\d+)?$`);
      const nDuplicates = form.inputNames.filter((name) => matchNameWithoutSuffix.test(name)).length;

      return nDuplicates === 0 ? name : `${name}_${nDuplicates + 1}`;
    },
    [form.inputNames]
  );

  const value = useMemo(
    () => ({ allInputs, getInputByName, mutateRowInputs, addInput, removeInput, updateInputSpec, generateInputName }),
    [allInputs, getInputByName, mutateRowInputs, addInput, removeInput, updateInputSpec, generateInputName]
  );

  return <FormInputsDataContext.Provider value={value}>{children}</FormInputsDataContext.Provider>;
}

/**
 * Uses the form inputs data.
 */
export function useFormInputs() {
  return useContext(FormInputsDataContext);
}
