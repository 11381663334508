import axios from 'axios';
import { ISession, ApiContext, IApiContext, IImpersonate } from 'api/api-context';
import { ActionName, actions, GetActionMap } from 'api/actions/actions';
import useLocalStorage from 'hooks/use-local-storage';
import { REST_API_TIMEOUT, REST_API_URL } from 'env';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LOGIN_PAGE_PATH } from 'routes/paths';
import { notifications } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Creates an empty session.
 */
const createEmptySession = () => ({
  jwt: '',
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  userId: -1,
  role: '',
  roleId: -1,
  permissions: null,
  organizationName: '',
});

/**
 * The session provider is used to provide the session context.
 */
export default function ApiProvider({ children }: { children: React.ReactNode }) {
  const [ready, setReady] = useState(false);
  const [shouldResumeSession, setShouldResumeSession] = useState(false);
  const [{ jwt, ...session }, setSession] = useLocalStorage<ISession>('fmpoint.session', createEmptySession());

  const [{ impersonate }, setImpersonateImpl] = useLocalStorage<{ impersonate?: IImpersonate }>(
    'fmpoint.impersonate',
    {}
  );

  const setImpersonate = useCallback(
    (impersonate: IImpersonate | undefined) => setImpersonateImpl({ impersonate }),
    [setImpersonateImpl]
  );

  const firstName = impersonate?.firstName ?? session.firstName;
  const lastName = impersonate?.lastName ?? session.lastName;
  const fullName = impersonate?.fullName ?? session.fullName;
  const email = impersonate?.email ?? session.email;
  const userId = impersonate?.userId ?? session.userId;
  const role = impersonate?.role ?? session.role;
  const roleId = impersonate?.roleId ?? session.roleId;
  const permissions = impersonate?.permissions ?? session.permissions;
  const organizationName = impersonate?.organizationName ?? session.organizationName;

  /**
   * Destroys the session.
   */
  const destroySession = useCallback(() => {
    setSession(createEmptySession());
    setImpersonate(undefined);
    window.open(`${LOGIN_PAGE_PATH.original}?logout=true`, '_self');
  }, [setSession, setImpersonate]);

  /**
   * Creates a contextualized action.
   */
  const getAction: GetActionMap = useMemo(() => {
    const connector = axios.create({
      timeout: REST_API_TIMEOUT,
      baseURL: REST_API_URL,
    });

    /**
     * Adds JWT to the request.
     */
    connector.interceptors.request.use((cfg) => {
      if (jwt) {
        cfg.headers.Authorization = `Bearer ${jwt}`;
      }

      if (impersonate && ready) {
        cfg.headers['X-Impersonate'] = impersonate.userId;
      }

      return cfg;
    });

    connector.interceptors.response.use(
      (response) => response.data.response,
      (error) => {
        if (error.response?.status === 401) {
          destroySession();
        }

        return Promise.reject(error);
      }
    );

    const contextualizedActions = new Map(
      Array.from(actions.entries()).map(([name, createAction]) => [name, createAction(connector)])
    );

    return (action: ActionName) => contextualizedActions.get(action) as any;
  }, [jwt, impersonate, ready]);

  /**
   * Logs the user in and stores the session.
   */
  const login: IApiContext['login'] = useCallback(
    ({ payload: { email, password, rememberMe } }) => {
      const loginAction = getAction('AuthLogin');

      return loginAction({ payload: { email, password, rememberMe } }).then((response) => {
        const { firstName, lastName, fullName, permissions, role, roleId, userId, token, organizationName } = response;

        setSession({
          jwt: token!,
          firstName,
          lastName,
          fullName,
          email,
          userId,
          role,
          roleId,
          permissions,
          organizationName,
        });

        return response;
      });
    },
    [getAction, setSession]
  );

  /**
   * Initialize the session.
   */
  const initSession = useCallback(async () => {
    const userGetMeAction = getAction('AuthLoggedUserInfo');

    try {
      const { email, firstName, lastName, fullName, userId, permissions, role, roleId, token, organizationName } =
        await userGetMeAction();

      setSession({
        jwt: token!,
        firstName,
        lastName,
        fullName,
        email,
        userId,
        role,
        roleId,
        permissions,
        organizationName,
      });
    } catch (err) {
      destroySession();
      throw err;
    }
  }, [impersonate, destroySession, getAction, setSession]);

  const hasPermissionAnywhere = useCallback(
    (permission: string) => {
      const map = permissions?.anywhere ?? {};

      if (permission.startsWith('*')) {
        const suffix = permission.slice(1);

        for (const key of Object.keys(map)) {
          if (key.endsWith(suffix) && map[key]) {
            return true;
          }
        }
      }

      return !!map[permission];
    },
    [permissions]
  );

  // Init session.
  useEffect(() => {
    if (jwt) {
      initSession()
        .catch(() => setShouldResumeSession(true))
        .finally(() => setReady(true));
    } else {
      setReady(true);
    }
  }, []); // Run only once.

  // Show notification when impersonating.
  useEffect(() => {
    if (impersonate) {
      notifications.show({
        color: SUCCESS_NOTIFICATION_COLOR,
        title: `Vystupujete ako ${impersonate.fullName}`,
        message: 'Pre návrat do svojho účtu kliknite na tlačidlo krížika pri mene užívateľa v ľavom dolnom rohu.',
      });
    }
  }, [impersonate]);

  return (
    <ApiContext.Provider
      value={{
        ready,
        shouldResumeSession,
        jwt,
        firstName,
        lastName,
        fullName,
        email,
        userId,
        role,
        roleId,
        permissions,
        organizationName,
        impersonate,
        originalSession: session,
        hasPermissionAnywhere,
        logout: destroySession,
        getAction,
        login,
        setImpersonate,
      }}
    >
      {ready ? children : <></>}
    </ApiContext.Provider>
  );
}
