import { ActionIcon, Box, Button, createStyles, Group, rem, Stack, Tooltip } from '@mantine/core';
import { IconMenu, IconPlus, IconTrashX } from '@tabler/icons-react';
import { useConfirm } from 'components/modals/message/MessageProvider';
import FormRow, { FormRowTexts } from 'pages/revisions-module/template-editor/editors/form/row/FormRow';
import {
  IConfigureInputParams,
  useInputConfigurator,
} from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';
import FormSectionName from 'pages/revisions-module/template-editor/editors/form/section/FormSectionName';
import { IFormInputContext, IFormSection } from 'pages/revisions-module/template-editor/editors/form/types';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import { useFormRows } from 'pages/revisions-module/template-editor/editors/form/row/FormRowsDataProvider';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { useMemo } from 'react';

/**
 * Texts for the FormSection component.
 */
export interface FormSectionTexts {
  removeTooltip: string;
  removeConfirm: string;
  moveTooltip: string;
  newRow: string;
  rowTexts?: FormRowTexts;
}

/**
 * Parameters of the FormSection component.
 */
export interface FormSectionProps {
  data: IFormSection;
  context: IFormInputContext;
  helpText?: string;
  readOnlyName?: boolean;
  disableDelete?: boolean;
  maxInputsInRow?: number;
  texts?: FormSectionTexts;
  configureInputParams?: Partial<IConfigureInputParams>;
}

/**
 * Styles for the FormSection component.
 */
const useStyles = createStyles((theme) => ({
  wrapper: {
    borderRadius: rem(4),
    overflow: 'hidden',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
  },
  header: {
    borderBottom: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: `${rem(4)} ${rem(4)} 0 0`,
  },
  addRowButtonWrapper: {
    border: `${rem(1)} dashed ${theme.colors.blue[5]}`,
    borderRadius: rem(8),
  },
}));

/**
 * A single section in the form editor.
 */
export default function FormSection({
  data,
  context,
  helpText,
  readOnlyName = false,
  disableDelete = false,
  maxInputsInRow,
  texts = {
    moveTooltip: 'Presunúť sekciu (drag & drop)',
    removeTooltip: 'Odstrániť sekciu',
    removeConfirm: 'Naozaj chcete odstrániť túto sekciu? Táto akcia je nevratná.',
    newRow: 'Pridať pole do nového riadku',
    rowTexts: undefined,
  },
  configureInputParams = {},
}: FormSectionProps) {
  const { classes } = useStyles();
  const { confirm } = useConfirm();
  const { newestSectionId, removeSection, updateSectionName } = useFormSections();
  const { addRow } = useFormRows();
  const { addInput } = useFormInputs();
  const { configureInput } = useInputConfigurator();

  const canBeDeleted = useMemo(
    () => data.rows.every((row) => row.inputs.every(({ deletable }) => deletable)),
    [data.rows]
  );

  /** Confirm removing the section. */
  const confirmRemoveSection = () =>
    confirm({
      title: texts.removeTooltip,
      content: texts.removeConfirm,
      onConfirm: () => removeSection(data.id),
    });

  /** Creates a new row with a new input */
  const addInputToNewRow = () =>
    configureInput({
      ...configureInputParams,
      primaryButtonLabel: texts.newRow,
      onSubmit: (spec) => {
        const rowId = addRow(data.id);
        addInput(data.id, rowId, context, spec);
      },
    });

  return (
    <Group spacing={8} noWrap>
      <Tooltip label={texts.moveTooltip} openDelay={300} withArrow={false}>
        <ActionIcon variant="subtle-gray" size="sm">
          <IconMenu />
        </ActionIcon>
      </Tooltip>
      <Stack w="calc(100% - 40px)" spacing={0} className={classes.wrapper}>
        <Group mih={74} position="apart" spacing={24} p={16} bg="gray.0" className={classes.header}>
          <Group spacing={8}>
            <FormSectionName
              value={data.name}
              onChange={(name) => updateSectionName(data.id, name)}
              readOnly={readOnlyName}
              autoFocus={newestSectionId === data.id}
            />
            {helpText && <QuestionMarkTooltip label={helpText} />}
          </Group>
          {!disableDelete && (
            <Tooltip label={texts.removeTooltip} openDelay={300} withArrow={false} onClick={confirmRemoveSection}>
              <ActionIcon variant="subtle-gray" size="md" disabled={!canBeDeleted}>
                <IconTrashX />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
        <Stack spacing={0} px={16}>
          {data.rows.map((row) => (
            <FormRow
              key={row.id}
              sectionId={data.id}
              data={row}
              context={context}
              maxInputsInRow={maxInputsInRow}
              texts={texts.rowTexts}
              configureInputParams={configureInputParams}
            />
          ))}
        </Stack>
        <Box p={16}>
          <Box p={8} className={classes.addRowButtonWrapper}>
            <Button variant="subtle" size="md" leftIcon={<IconPlus />} fullWidth onClick={addInputToNewRow}>
              {texts.newRow}
            </Button>
          </Box>
        </Box>
      </Stack>
    </Group>
  );
}
