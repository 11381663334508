import { ActionIcon, createStyles, Group, Menu, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDotsVertical, IconPencil, IconTrashX } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useConfirm } from 'components/modals/message/MessageProvider';
import {
  IConfigureInputParams,
  useInputConfigurator,
} from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';
import { IFormInput } from 'pages/revisions-module/template-editor/editors/form/types';
import FormInputPreview from 'pages/revisions-module/template-editor/editors/form/input/FormInputPreview';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';

/**
 * Parameters of the FormInput component.
 */
interface FormInputProps {
  sectionId: string;
  rowId: string;
  data: IFormInput;
  configureInputParams?: Partial<IConfigureInputParams>;
}

/**
 * Styles for the FormInput component.
 */
const useStyles = createStyles<'wrapper', { menuOpened: boolean }>((theme, { menuOpened = false }) => {
  const invisibleBorder = `${rem(1)} dashed transparent`;
  const activeBorder = `${rem(1)} dashed ${theme.colors.blue[5]}`;

  return {
    wrapper: {
      border: menuOpened ? activeBorder : invisibleBorder,
      borderRadius: rem(8),
      transition: 'all 100ms ease',
      '& .actions': {
        opacity: menuOpened ? 1 : 0,
        transition: 'opacity 100ms ease',
      },
      ':hover': {
        border: activeBorder,
        '& .actions': {
          opacity: 1,
        },
      },
    },
  };
});

/**
 * A single input in a form row.
 */
export default function FormInput({ sectionId, rowId, data, configureInputParams = {} }: FormInputProps) {
  const [menuOpened, { open: openMenu, close: closeMenu }] = useDisclosure(false);
  const styleParams = useMemo(() => ({ menuOpened }), [menuOpened]);
  const { classes } = useStyles(styleParams);
  const { confirm } = useConfirm();
  const { removeInput, updateInputSpec } = useFormInputs();
  const { configureInput } = useInputConfigurator();

  /** Confirm and remove the input. */
  const confirmRemoveInput = () =>
    confirm({
      title: 'Odstrániť pole',
      content: 'Naozaj chcete odstrániť toto pole? Táto akcia je nevratná.',
      onConfirm: () => removeInput(sectionId, rowId, data.id),
    });

  /** Open the input configurator. */
  const reconfigureInput = () =>
    configureInput({
      ...configureInputParams,
      primaryButtonLabel: 'Uložiť zmeny',
      initialValues: data.spec,
      readOnly: { name: true, type: true },
      onSubmit: (spec) => updateInputSpec(sectionId, rowId, data.id, spec),
    });

  return (
    <Group p={8} position="apart" noWrap align="end" className={classes.wrapper} pos="relative">
      <FormInputPreview data={data} />
      <Menu opened={menuOpened} onOpen={openMenu} onClose={closeMenu}>
        <Menu.Target>
          <ActionIcon pos="absolute" top={4} right={4} w={24} h={24} p={4} className="actions" variant="subtle-gray">
            <IconDotsVertical />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconPencil />} color="gray.7" onClick={reconfigureInput}>
            Upraviť pole
          </Menu.Item>
          <Menu.Item icon={<IconTrashX />} color="red.8" onClick={confirmRemoveInput} disabled={!data.deletable}>
            Odstrániť pole
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
